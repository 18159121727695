import React from "react";
import { useTranslation, Trans, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component";
import CommonLayout from "../../components/layout/CommonLayout";
import SEO from "../../components/seo";
import Thumbnail from "../../images/LandingPage/OnlineSelling/thumbnailOnlineSelling.jpg";
import Banner from "../../components/LandingPage/Banner/index";
import SellingContainer from "../../components/LandingPage/OnlineSelling/SellingContainer";
import WebKuu from "../../components/LandingPage/OnlineSelling/WebKuu";
// import Register from "../../components/LandingPage/OnlineSelling/Register";
import FirstComponent from "../../components/LandingPage/OnlineSelling/FirstComponent";
import SecondComponent from "../../components/LandingPage/OnlineSelling/SecondComponent";
import { StockAnimation } from "../../components/Home/ImageAnimation";
import FourthComponent from "../../components/LandingPage/OnlineSelling/FourthComponent";
import ThirdComponent from "../../components/LandingPage/OnlineSelling/ThirdComponent";

const SocialIcon = loadable(() =>
  import("../../components/LandingPage/SocialIcon")
);

const getImages = (images, currentLanguage) => {
  return {
    edges: images.edges.filter((imageFile) =>
      imageFile.node.relativeDirectory.includes(`${currentLanguage}`)
    ),
  };
};

const OnlineSelling = ({
  data: { stockSlide, thirdSlideContent },
  location: { pathname },
  path,
}) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <CommonLayout>
      <SEO title="Online selling" pathname={pathname} metaImage={Thumbnail} />
      <Banner
        imageComponent={
          <StaticImage
            placeholder="tracedSVG"
            quality="70"
            src="../../images/LandingPage/OnlineSelling/Cover/SellingBanner.png"
            alt="ContentBanner"
          />
        }
        paragraph={
          <>
            <h2>
              <Trans components={[<br />]}>
                {t("landing.onlineSelling.banner.Create Marketing")}
              </Trans>
            </h2>

            <h2 className="subTitle">
              {t("landing.onlineSelling.banner.Fast & easy")}
            </h2>
            <h3>
              <Trans components={[<br />]}>
                {t("landing.onlineSelling.banner.Ideal for online")}
              </Trans>
            </h3>
          </>
        }
      />
      <section>
        <SellingContainer
          styledBackground={{
            content: "' '",
            background: "#f3f3f3",
            position: "absolute",
            width: "100%",
            borderRadius: "40% / 0% 100% 100% 0%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
            transform: "translate(-75%, 0)",
          }}
          paragraph={
            <>
              <div className="number">01</div>
              <h1>
                <Trans components={[<br />, <strong className="bold-color" />]}>
                  {t("landing.onlineSelling.01.topic")}
                </Trans>
              </h1>
              <ul>
                <li>
                  <Trans components={[<br />]}>
                    {t("landing.onlineSelling.01.list-01")}
                  </Trans>
                </li>
                <li>
                  <Trans components={[<br />, <strong />]}>
                    {t("landing.onlineSelling.01.list-02")}
                  </Trans>
                </li>
              </ul>
            </>
          }
          imageContent={<FirstComponent />}
        />
      </section>
      <section>
        <SellingContainer
          reverseOrder={true}
          paragraph={
            <>
              <div className="number">02</div>
              <h1>
                <Trans
                  components={[
                    <br />,
                    <strong className="bold-color" />,
                    <strong className="bold-color br-word" />,
                  ]}
                >
                  {t("landing.onlineSelling.02.topic")}
                </Trans>
              </h1>
              <ul>
                <li>
                  <Trans components={[<br />, <strong />]}>
                    {t("landing.onlineSelling.02.list-01")}
                  </Trans>
                </li>
                <li>
                  <Trans components={[<br />, <strong />]}>
                    {t("landing.onlineSelling.02.list-02")}
                  </Trans>
                </li>
              </ul>
            </>
          }
          imageContent={<SecondComponent />}
        />
      </section>
      <section>
        <div
          style={{
            maxWidth: 700,
            margin: "2em auto",
          }}
        >
          <StockAnimation stockSlide={stockSlide} />
        </div>
      </section>
      <section>
        <SellingContainer
          styledText={{
            background: "#fff",
            borderRadius: 4,
          }}
          styledBackground={{
            content: "' '",
            background: "#3B75A4",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
            transform: "translate(-75%, 0px)",
          }}
          paragraph={
            <>
              <div className="number">03</div>
              <h1>
                <Trans components={[<br />, <strong className="bold-color" />]}>
                  {t("landing.onlineSelling.03.topic")}
                </Trans>
              </h1>
              <h2>
                <Trans components={[<br />]}>
                  {t("landing.onlineSelling.03.sub")}
                </Trans>
              </h2>
            </>
          }
          imageContent={
            <ThirdComponent
              imageObject={getImages(thirdSlideContent, language)}
            />
          }
        />
      </section>
      <section>
        <SellingContainer
          paragraph={
            <>
              <div className="number">04</div>
              <h1>
                <Trans components={[<br />, <strong className="bold-color" />]}>
                  {t("landing.onlineSelling.04.topic")}
                </Trans>
              </h1>
              <div className="wrap-icon">
                <SocialIcon />
              </div>
            </>
          }
          imageContent={<FourthComponent />}
        />
      </section>
      <section>
        <WebKuu
          paragraph={
            <>
              <h1>
                <Trans
                  components={[
                    <br />,
                    <strong />,
                    <strong className="highlight" />,
                  ]}
                >
                  {t("landing.onlineSelling.webKuu.topic")}
                </Trans>
              </h1>
              <h2>{t("landing.onlineSelling.webKuu.sub")}</h2>
              <a href="/">www.KUUpixmaker.ai</a>
            </>
          }
        />
      </section>
      {/* <section id="register">
        <Register
          pathName={path.replace("online-selling/", "")}
          paramName={"online-selling"}
        />
      </section> */}
    </CommonLayout>
  );
};
export default OnlineSelling;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    stockSlide: allFile(
      filter: { relativeDirectory: { eq: "Slide/Cutout" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          base
          id
          childImageSharp {
            gatsbyImageData(
              width: 700
              placeholder: BLURRED
              quality: 80
              blurredOptions: { width: 100 }
            )
          }
        }
      }
    }
    thirdSlideContent: allFile(
      filter: {
        relativePath: { glob: "LandingPage/OnlineSelling/Slide/**/*.png" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          base
          id
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              quality: 80
              blurredOptions: { width: 100 }
            )
          }
        }
      }
    }
  }
`;
