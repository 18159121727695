import React, { useState, useEffect, useRef } from "react";
import { useTransition, animated } from "@react-spring/web";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";

const Content = styled.div`
  position: relative;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : "65%"};
`;

const WrapperAnimated = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const BannerAnimation = ({ imageSlide }) => {
  const [items, set] = useState(0);
  const [play, setPlay] = useState(false);
  const animationRef = useRef(null);
  const transitions = useTransition(items, {
    keys: items,
    from: { opacity: 0, filter: "blur(5px)" },
    enter: { opacity: 1, filter: "blur(0px)" },
    leave: { opacity: 0, filter: "blur(5px)" },
    config: { duration: 300 },
  });

  useEffect(() => {
    const animationObserver = new IntersectionObserver(
      (entries, videoObserver) => {
        if (!entries[0].isIntersecting) {
          return;
        } else {
          setPlay(true);
          animationObserver.unobserve(entries[0].target);
        }
      }
    );

    let letID;
    if (play) {
      letID = setInterval(() => {
        set((state) => (state + 1) % imageSlide.edges.length);
      }, 3000);
    }

    if (animationRef.current) animationObserver.observe(animationRef.current);

    return () => {
      clearInterval(letID);
      animationObserver.disconnect();
    };
  }, [imageSlide.edges.length, play]);

  return (
    <Content paddingBottom={"73%"}>
      {transitions((style, item) => (
        <animated.div
          ref={animationRef}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",

            willChange: "opacity",
            ...style,
          }}
          key={item}
        >
          <GatsbyImage
            loading="lazy"
            image={imageSlide.edges[item].node.childImageSharp.gatsbyImageData}
            alt={imageSlide.edges[item].node.base}
          />
        </animated.div>
      ))}
    </Content>
  );
};

const StockAnimation = ({ stockSlide }) => {
  const [indexFeatures, set] = React.useState(0);
  const [play, setPlay] = useState(false);
  const animationStockRef = useRef(null);
  const transitions = useTransition(indexFeatures, {
    keys: indexFeatures,
    from: { opacity: 0, filter: "blur(5px)" },
    enter: { opacity: 1, filter: "blur(0px)" },
    leave: { opacity: 0, filter: "blur(5px)" },
    config: { duration: 300 },
  });

  useEffect(() => {
    const animationObserver = new IntersectionObserver(
      (entries, videoObserver) => {
        if (!entries[0].isIntersecting) {
          return;
        } else {
          setPlay(true);
          animationObserver.unobserve(entries[0].target);
        }
      }
    );

    let letID;
    if (play) {
      letID = setInterval(() => {
        set((state) => (state + 1) % stockSlide.edges.length);
      }, 3000);
    }

    if (animationStockRef.current)
      animationObserver.observe(animationStockRef.current);

    return () => {
      clearInterval(letID);
      animationObserver.disconnect();
    };
  }, [stockSlide.edges.length, play]);

  return (
    <>
      <Content>
        {transitions((style, item) => (
          <WrapperAnimated
            ref={animationStockRef}
            style={{
              willChange: "opacity",
              ...style,
            }}
          >
            <GatsbyImage
              loading="lazy"
              image={
                stockSlide.edges[item].node.childImageSharp.gatsbyImageData
              }
              alt={stockSlide.edges[item].node.base}
            />
          </WrapperAnimated>
        ))}
      </Content>
    </>
  );
};

const InspirationAnimation = ({ inspirationSlide }) => {
  const [indexFeatures, set] = useState(0);
  const [play, setPlay] = useState(false);
  const animationInspirationRef = useRef(null);
  const transitions = useTransition(indexFeatures, {
    keys: indexFeatures,
    from: { opacity: 0, filter: "blur(5px)" },
    enter: { opacity: 1, filter: "blur(0px)" },
    leave: { opacity: 0, filter: "blur(5px)" },
    config: { duration: 300 },
  });

  useEffect(() => {
    const animationObserver = new IntersectionObserver(
      (entries, videoObserver) => {
        if (!entries[0].isIntersecting) {
          return;
        } else {
          setPlay(true);
          animationObserver.unobserve(entries[0].target);
        }
      }
    );

    let letID;
    if (play) {
      letID = setInterval(() => {
        set((state) => (state + 1) % inspirationSlide.edges.length);
      }, 3000);
    }

    if (animationInspirationRef.current)
      animationObserver.observe(animationInspirationRef.current);

    return () => {
      clearInterval(letID);
      animationObserver.disconnect();
    };
  }, [inspirationSlide.edges.length, play]);

  return (
    <>
      <Content paddingBottom={"100%"}>
        {transitions((style, item) => (
          <WrapperAnimated
            ref={animationInspirationRef}
            style={{
              willChange: "opacity",
              ...style,
            }}
          >
            <GatsbyImage
              loading="lazy"
              image={
                inspirationSlide.edges[item].node.childImageSharp
                  .gatsbyImageData
              }
              alt={inspirationSlide.edges[item].node.base}
            />
          </WrapperAnimated>
        ))}
      </Content>
    </>
  );
};

export { BannerAnimation, StockAnimation, InspirationAnimation };
