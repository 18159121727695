import React from "react";
import mobileWeb from "../../../images/LandingPage/OnlineSelling/02/MoblieAD.webm";
import mobileMp from "../../../images/LandingPage/OnlineSelling/02/MoblieAD.mp4";

const SecondComponent = () => {
  return (
    <>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          justifySelf: "center",
          maxWidth: 400,
          width: "100%",
          height: "auto",
        }}
      >
        <source src={mobileWeb} type="video/webm" />
        <source src={mobileMp} type="video/mp4" />
      </video>
    </>
  );
};

export default SecondComponent;
