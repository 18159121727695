import React from "react";
import resizingWeb from "../../../images/LandingPage/OnlineSelling/04/ResizingAD.webm";
import resizingMp from "../../../images/LandingPage/OnlineSelling/04/ResizingAD.mp4";

const FourthComponent = () => {
  return (
    <>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          justifySelf: "center",
          maxWidth: 400,
          width: "100%",
          height: "auto",
        }}
      >
        <source src={resizingWeb} type="video/webm" />
        <source src={resizingMp} type="video/mp4" />
      </video>
    </>
  );
};

export default FourthComponent;
