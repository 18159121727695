import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import chairWeb from "../../../images/LandingPage/OnlineSelling/01/EcomChair.webm";
import chairMp from "../../../images/LandingPage/OnlineSelling/01/EcomChair.mp4";
import { WrapperGallery } from "../../Style/SellingCss";

const FirstComponent = () => {
  return (
    <WrapperGallery>
      <div className="grid-gif">
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            display: "block",
            width: "100%",
            height: "auto",
          }}
        >
          <source src={chairWeb} type="video/webm" />
          <source src={chairMp} type="video/mp4" />
        </video>
      </div>
      <div className="grid-image">
        <StaticImage
          loading="lazy"
          placeholder="tracedSVG"
          quality="60"
          src="../../../images/LandingPage/OnlineSelling/01/Ecom01.png"
          alt="selling"
        />

        <StaticImage
          loading="lazy"
          placeholder="tracedSVG"
          quality="60"
          src="../../../images/LandingPage/OnlineSelling/01/Ecom02.png"
          alt="selling"
        />

        <StaticImage
          loading="lazy"
          placeholder="tracedSVG"
          quality="60"
          src="../../../images/LandingPage/OnlineSelling/01/Ecom03.png"
          alt="selling"
        />
      </div>
    </WrapperGallery>
  );
};

export default FirstComponent;
